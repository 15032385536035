import React from "react";
import { viewports } from "../../../style-vars";

const { smOrSmaller } = viewports;
// const { white, black } = colors;

const WhyStyles = () => (
  <style jsx>{`
    .left-a {
      top: 0px;
      left: 15%;
      width: 100%;
      max-width: 338px;
    }
    .left-b {
      top: calc(72px + 25%);
      left: 45px;
      width: 100%;
      max-width: 320px;
    }
    .left-c {
      top: calc(72px + 60%);
      left: 135px;
      width: 100%;
      max-width: 112px;
    }
    .left-d {
      top: calc(72px + 62%);
      left: 25%;
      width: 100%;
      max-width: 156px;
    }
    .left-e {
      top: calc(72px + 75%);
      left: 75px;
      width: 100%;
      max-width: 256px;
    }
    .left-f {
      top: calc(72px + 80%);
      left: 30%;
      width: 100%;
      max-width: 122px;
    }
    .right-a {
      top: 15%;
      right: 74px;
      width: 100%;
      max-width: 302px;
    }
    .right-b {
      top: 45%;
      right: 45px;
      width: 100%;
      max-width: 122px;
    }
    .right-c {
      top: 55%;
      right: 15%;
      width: 100%;
      max-width: 256px;
    }
    .right-d {
      top: 75%;
      right: 45px;
      width: 100%;
      max-width: 167px;
    }
    .right-e {
      top: 80%;
      right: 17%;
      width: 100%;
      max-width: 122px;
    }
    .right-f {
      top: 80%;
      right: 17%;
      width: 100%;
      max-width: 122px;
    }
    @media screen and (max-width: 1366px) {
      .left-a {
        top: 0px;
        left: 15%;
        width: 100%;
        max-width: 338px;
      }
      .left-b {
        top: calc(72px + 25%);
        left: 45px;
        width: 100%;
        max-width: 180px;
      }
      .left-c {
        top: calc(72px + 60%);
        left: 135px;
        width: 100%;
        max-width: 112px;
      }
      .left-d {
        top: calc(72px + 62%);
        left: 25%;
        width: 100%;
        max-width: 156px;
      }
      .left-e {
        top: calc(72px + 75%);
        left: 75px;
        width: 100%;
        max-width: 256px;
      }
      .left-f {
        top: calc(72px + 80%);
        left: 30%;
        width: 100%;
        max-width: 122px;
      }
      .right-a {
        top: 15%;
        right: 74px;
        width: 100%;
        max-width: 180px;
      }
      .right-b {
        top: 45%;
        right: 45px;
        width: 100%;
        max-width: 122px;
      }
      .right-c {
        top: 55%;
        right: 15%;
        width: 100%;
        max-width: 256px;
      }
      .right-d {
        top: 75%;
        right: 45px;
        width: 100%;
        max-width: 167px;
      }
      .right-e {
        top: 90%;
        right: 17%;
        width: 100%;
        max-width: 122px;
      }
      .right-f {
        top: 80%;
        right: 17%;
        width: 100%;
        max-width: 122px;
      }
    }
    @media screen and (max-width: 1024px) {
      .left-a {
        top: 90px;
        left: 15%;
        width: 100%;
        max-width: 238px;
        min-width: 150px;
      }
      .left-b {
        top: calc(72px + 25%);
        left: 45px;
        width: 100%;
        max-width: 180px;
        min-width: 150px;
      }
      .left-c {
        top: calc(72px + 45%);
        left: 135px;
        width: 100%;
        max-width: 112px;
      }
      .left-d {
        top: calc(72px + 52%);
        left: 25%;
        width: 100%;
        max-width: 156px;
      }
      .left-e {
        top: calc(72px + 65%);
        left: 75px;
        width: 100%;
        max-width: 256px;
      }
      .left-f {
        top: calc(72px + 89%);
        left: 30%;
        width: 100%;
        max-width: 122px;
      }
      .right-a {
        top: 15%;
        right: 74px;
        width: 100%;
        max-width: 180px;
      }
      .right-b {
        top: 35%;
        right: 45px;
        width: 100%;
        max-width: 122px;
      }
      .right-c {
        top: 45%;
        right: 15%;
        width: 100%;
        max-width: 256px;
      }
      .right-d {
        top: 75%;
        right: 45px;
        width: 100%;
        max-width: 167px;
      }
      .right-e {
        top: 90%;
        right: 17%;
        width: 100%;
        max-width: 122px;
      }
      .right-f {
        top: 80%;
        right: 17%;
        width: 100%;
        max-width: 122px;
      }
    }
    @media screen and (max-width: 1020px) {
      .left-b {
        top: calc(72px + 35%);
        left: 45px;
      }
      .left-c {
        display: none;
      }
      .left-d {
        display: none;
      }
      .left-e {
        display: none;
      }
      .left-f {
        display: none;
      }
      .right-a {
        top: 10%;
        right: 74px;
      }
      .right-c {
        top: 45%;
        right: 45px;
      }
      .right-b {
        display: none;
      }
      .right-d {
        top: 75%;
        right: 55%;
      }
      .right-e {
        display: none;
      }
      .right-f {
        display: none;
      }
    }
    @media screen and (max-width: 600px) {
      .left-a {
        top: 9%;
        left: 45px;
        max-width: 150px !important;
      }
      .left-b {
        top: calc(72px + 55%);
        left: 16px;
        max-width: 117px;
      }
      .right-a {
        top: 15%;
        right: 16px;
        max-width: 81px !important;
      }
      .right-c {
        top: 55%;
        right: 16px;
        max-width: 81px !important;
      }
      .right-d {
        top: 87%;
        max-width: 100px;
        right: 32px;
      }
    }
    .no-margin {
      margin-right: 0 !important;
    }
  `}</style>
);

export default WhyStyles;
