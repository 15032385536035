import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { motion, useAnimation } from "framer-motion";
import { isMobileOnly, isTablet } from "react-device-detect";
import { useInView } from "react-intersection-observer";
import WhyStyles from "./WhyStyles";
import CtaPrimary from "../../../components-v2/Base/CtaPrimary";
import Image from "../../ImageQuerys/LargeFeaturesImages";

const container = {
  show: {
    transition: {
      staggerChildren: 0.1,
      delay: 1,
    },
  },
};

const item = {
  hidden: {
    opacity: 0,
    y: 200,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      ease: [0.6, 0.01, -0.05, 0.95],
      duration: 1.6,
    },
    exit: {
      opacity: 0,
      y: -200,
      transition: {
        ease: "easeInOut",
        duration: 0.8,
      },
    },
  },
};

const imgArr = [
  "left-a.png",
  "left-e.png",
  "left-f.png",
  "right-e.png",
  "right-b.png",
  "left-c.png",
  "right-d.png",
  "left-d.png",
  "right-a.png",
  "right-c.png",
  "left-b.png",
];

const NewWhyHero = (props) => {
  const { sectionData } = props;
  const controls = useAnimation();
  const { ref, inView } = useInView();

  useEffect(() => {
    if (inView) {
      controls.start("show");
    }
    if (!inView) {
      controls.start("exit");
    }
  }, [controls, inView]);
  return (
    <section className="flex max-h-[1200px] h-full min-h-[900px] lg:min-h-[1400px]  w-full relative border-box ">
      <Image
        imageName="hero-half-circle.png"
        style={{ position: "absolute" }}
        className="absolute l-0 top-1/2 md:top-1/4 lg:w-52 w-24"
      />
      <div className="max-w-1440 lg:mx-auto relative w-full">
        <motion.div
          variants={container}
          ref={ref}
          initial={isMobileOnly || isTablet ? "show" : "hidden"}
          animate={controls}
          exit="exit"
        >
          {imgArr.map((img) => (
            <motion.div
              key={img}
              className={`${img.split(".")[0]} absolute`}
              style={{ position: "absolute" }}
              variants={item}
            >
              <Image imageName={img} />
            </motion.div>
          ))}
        </motion.div>
        <div className="flex justify-center align-middle mx-auto border-box mt-[252px] md:mt-[35%] xl:mt-[252px] overflow-hidden">
          <div className="max-w-[644px] relative xl:fixed border-box">
            <motion.div
              variants={item}
              initial={isMobileOnly ? "show" : "hidden"}
              animate="show"
              className="mx-4 md:mx-0"
            >
              <h3 className="text-center p-0 text-h3-small lg:text-h3">
                Why SpotOn?
              </h3>
              <h1 className="text-black font-bold text-h1-small lg:text-h1 text-center p-0 mb-12 ">
                {sectionData.title}
                <span className="blue_dot">.</span>
              </h1>
              <div className="flex justify-center">
                {sectionData.PrimaryCta && (
                  <CtaPrimary
                    title={sectionData.PrimaryCta.ctaTitle}
                    target={sectionData.PrimaryCta.ctaTarget}
                    className="m-0 no-margin"
                  />
                )}
              </div>
            </motion.div>
          </div>
        </div>
      </div>
      <WhyStyles />
    </section>
  );
};

NewWhyHero.propTypes = {
  sectionData: PropTypes.objectOf(PropTypes.any),
};
NewWhyHero.defaultProps = {
  sectionData: {},
};

export default NewWhyHero;
